/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "whatwg-fetch";
import cookie from "cookie";

import "./src/styles/reset.css";
import "./src/styles/global.css";

export { wrapPageElement } from "./src/components/PageWrapper";

export const onRouteUpdate = ({ location }) => {
  const trackingId = window.GA_TRACKING_ID;
  const custom_map = window.GA_CUSTOM_MAP;

  // Turn of no scroll on any navigation
  document?.body?.classList.remove("no-scroll");

  if (!trackingId || typeof window.gtag !== `function`) {
    return;
  }

  let locationStr = "";

  if (location) {
    locationStr = `${location.pathname}${location.search}${location.hash}`;
  }

  const options = {
    page_path: locationStr,
    anonymize_ip: true,
    custom_map,
  };

  const test = cookie.parse(document.cookie).sp_t;
  if (test) {
    options.test = test;
  }

  window.gtag("config", trackingId, options);
};
