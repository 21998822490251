import React from "react";

export const SplitContext = React.createContext();

const Wrapper = ({ children, pageContext: { split } }) => {
  return (
    <SplitContext.Provider value={{ split }}>{children}</SplitContext.Provider>
  );
};

export const wrapPageElement = ({ element, props }) => (
  <Wrapper {...props}>{element}</Wrapper>
);
